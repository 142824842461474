@import "./variables";

body {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: $fontFamilyMuseo300;
}

.leaflet-control-attribution {
    display: none;
}

.leaflet-div-icon {
    background: none !important;
    border: none !important;
}

.leaflet-cluster-marker {
    border: 2px solid black;
    color: black;
    font-weight: bold;
    background: $blueMarker;
    border-radius: 50%;
    padding: 10px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leaflet-cluster-marker-active {
    border: 2px solid black;
    color: black;
    font-weight: bold;
    background: $tcsRed;
    border-radius: 50%;
    padding: 10px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leaflet-marker-red-circle {
    background: $tcsRed;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.app-bar-mobile,
.map-control,
.dashboard-menu,
.dashboard-user {
    background-color: $tcsYellow;
}

.dashboard-list-item-active {
    background-color: $tcsRedLight !important;
}

.notification-dialog {
    .MuiTextField-root {
        .MuiFormHelperText-root {
            margin: 0 !important;
        }
    }

    .custom-helper {
        display: flex;
        align-items: center;
    }
}

.map-container {
    &.leaflet-container {
        width: 100%;
        height: 100vh;
    }
}

.dashboard-menu {
    position: fixed;
    bottom: 0;
    left: 0;
}

.age-filter {
    align-items: center;
    justify-content: space-between;
}

.search-bar {;
    width: 100%;
}

.tools-column {
    background-color: $tcsBeige2;
}

.last-user-locations,
.user-locations,
.notifications {
    background-color: white;
}

/*
.List {
    padding-top: 0;
    padding-bottom: 0;
}*/
